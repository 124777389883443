
































































































import {
  Component, Vue, Watch, Mixins 
} from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import WrapperContent from '@/components/WrapperContent/WrapperContent.vue';
import OptionsContent from '@/components/OptionsContent/OptionsContent.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import SubjectTools from '@/components/SubjectTools/SubjectTools.vue';
import ListAllBooks from '@/components/ListAllBooks/index.vue';
import Footer from '@/components/Footer/Footer.vue';
import PdfViewer from '@/components/PdfViewer/PdfViewer.vue';
import PdfViewerLoading from '@/components/PdfViewer/PdfViewerLoading.vue';
import FeedBackPermission from '@/components/FeedbackPermission/index.vue';
import MaterialsHeaderAccordion from './components/MaterialsHeaderAccordion/MaterialsHeaderAccordion.vue';
import MaterialsAccordion from './components/MaterialsAccordion/MaterialsAccordion.vue';
import ViewerContentListLoading from '@/components/ViewerContentListLoading/index.vue';
import MaterialsSidebar from './components/MaterialsSidebar/MaterialsSidebar.vue';

import HistoricService from '@/services/Historic/historicService';

import SetSidebar from '@/mixins/SetSidebar';
import PlannerData from '@/mixins/PlannerData';
import CourseData from '@/mixins/CourseData';
import BookData from '@/mixins/BookData';
import Permission from '@/mixins/permission';

import { LIST_NAME_CONTEXT } from '@/constant/Context';
import { LIST_PERMISSION } from '@/constant/ListPermission';
import { IDS_FOR_PERMISSION_COURSE } from '@/constant/CoursesId';
import { LIST_BOOK_ID } from '@/constant/ListBookId';

import { hasPermissionMaterials } from '@/share/Util/Permissions/materials';

import { TypeMaterial } from '@/services/Historic/historicInterface';

@Component({
  components: {
    PageHeader,
    ContainerFluid,
    WrapperContent,
    OptionsContent,
    BoxContainer,
    SubjectTools,
    ListAllBooks,
    PdfViewer,
    PdfViewerLoading,
    FeedBackPermission,
    MaterialsHeaderAccordion,
    MaterialsAccordion,
    ViewerContentListLoading,
    Footer
  }
})
export default class Materials extends Mixins(
  SetSidebar,
  PlannerData,
  CourseData,
  BookData,
  Permission
) {
  private isLoading = true;
  private errorLoadingListTopic = false;
  private isCheckOpen = true;
  private pdf = '';
  private contextID: number | null = -1;
  private orderTabs = {
    numberTabDoubts: 0,
    numberTabAnnotations: 1,
    numberTabListExercise: 2,
    numberTabListVideoOrMaterial: 3
  };
  private file: Record<string, any> = {};
  private topic: Record<string, any> = {};
  private ids: Array<number> = [];
  private allChecked: Array<Record<string, any>> = [];
  private currentChecked = false;
  private errorDataMaterials = false;

  private tabMenu = [
    { title: 'Dúvidas', slug: 'doubts', active: true },
    { title: 'Anotações', slug: 'notes', active: false },
    { title: 'Exercícios', slug: 'exercises', active: false },
    { title: 'Aulas', slug: 'videos', active: false }
  ];

  private historicService = new HistoricService();

  async created() {
    this.setSidebarMaterials();
    await this.takeGetBooks();
    this.loaderDataContext();
    await this.getBook();
    this.setBreadCrumbs();
    this.getFile();
  }

  beforeDestroy() {
    this.$store.commit('setSidebar', false);
    this.$store.commit('setSidebarExistence', false);
  }

  get permissionMaterial() {
    if (this.topic?.archives) {
      const material = this.topic?.archives.find(
        (file: Record<string, any>) => String(file.idArchive) === this.getParams.fileID
      );

      return this.hasPermissionMaterial(material);
    }

    return false;
  }

  get permissionDownloadMaterials() {
    return (
      this.can(LIST_PERMISSION.MATERIALS_DOWNLOAD)
      || this.can(LIST_PERMISSION.ALL_MATERIALS_REDACTION)
    );
  }

  get getIdTopic() {
    return Number(this.getParams.topicID);
  }

  get getIdFile() {
    return Number(this.getParams.fileID);
  }

  get getContext() {
    const { context, path } = this.getParams;
    if (context === LIST_NAME_CONTEXT.PLANNER) return context;

    if (context === LIST_NAME_CONTEXT.COURSE) return context;

    return path;
  }

  get contextIsBook() {
    return this.getContext === LIST_NAME_CONTEXT.BOOK;
  }

  get isMovie() {
    return this.$store.getters.isMovie;
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get getParams() {
    return this.$route.params;
  }

  get setSidebarTopic() {
    if (
      this.getContext === LIST_NAME_CONTEXT.PLANNER
      && this.currentWeekPlanner
      && this.currentWeekPlanner.length
    ) {
      return this.currentWeekPlanner;
    }

    if (
      this.getContext === LIST_NAME_CONTEXT.COURSE
      && this.currentCourseWeek?.topics
      && this.currentCourseWeek?.topics.length
    ) {
      return this.currentCourseWeek.topics;
    }

    if (this.currentBook && Object.entries(this.currentBook).length) {
      return this.currentBook?.topics;
    }

    return [];
  }

  get idCourseContext() {
    const { path } = this.$route.params;
    const splitPath = path.split('&');
    return splitPath[1];
  }

  hasPermissionMaterial(material: Record<string, any>) {
    if (this.getContext === LIST_NAME_CONTEXT.PLANNER) {
      return this.can(LIST_PERMISSION.PLANNER);
    }

    if (this.getContext === LIST_NAME_CONTEXT.COURSE) {
      return this.can(IDS_FOR_PERMISSION_COURSE[Number(this.idCourseContext)]);
    }

    if (
      this.currentBook.idBooks === LIST_BOOK_ID.REDACTION
      && this.can(LIST_PERMISSION.ALL_MATERIALS_REDACTION)
    ) return true;

    return hasPermissionMaterials(material);
  }

  @Watch('getIdTopic')
  async loaderDataContext() {
    try {
      this.isLoading = true;

      if (this.getContext === LIST_NAME_CONTEXT.PLANNER) {
        await this.loaderPlannerData();
      } else if (this.getContext === LIST_NAME_CONTEXT.COURSE) {
        await this.loaderCourseData();
      } else {
        this.getBook();
        await this.loaderBookData();
      }

      this.getIds();
      this.getAllMaterials();
      this.setCheckedCurrent();
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os dados do tópico.',
        status: 'error'
      });
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  async loaderPlannerData() {
    try {
      const { topic, contextID } = await this.setTopicPlanner();
      this.topic = topic;
      this.contextID = contextID;
    } catch (error) {
      this.errorLoadingListTopic = true;

      this.errorDataMaterials = true;
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os dados do planner.',
        status: 'error'
      });
      console.error(error);
    }
  }

  async loaderCourseData() {
    try {
      const { topic, contextID } = await this.setTopicCourse();
      this.topic = topic;
      this.contextID = contextID;
    } catch (error) {
      this.errorLoadingListTopic = true;

      this.errorDataMaterials = true;
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os dados do curso.',
        status: 'error'
      });
      console.error(error);
    }
  }

  @Watch('$route.params.path')
  async loaderBookData() {
    try {
      this.topic = await this.getTopicBook();
      this.contextID = await this.getContextID();
    } catch (error) {
      this.errorLoadingListTopic = true;

      this.errorDataMaterials = true;
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os dados do caderno.',
        status: 'error'
      });
      console.error(error);
    }
  }

  async getAllMaterials() {
    if (this.ids.length > 99) {
      const response = await this.getHistoric('materiais', this.ids.splice(0, 100));

      this.allChecked.push(...response);
      await this.getAllMaterials();
    } else {
      const response = await this.getHistoric('materiais', this.ids);

      this.allChecked.push(...response);
    }
  }

  async getHistoric(type: TypeMaterial, listIds: Array<number>) {
    try {
      const response = await this.historicService.getHistoric({ type, listIds });

      return response || [];
    } catch (error) {
      return [];
    }
  }

  async setCheckbox(type: string, checked: boolean) {
    this.currentChecked = !checked;

    await this.historicService.saveHistoric({
      idobject: this.file.idArchive,
      type: 'materiais',
      checked: this.currentChecked
    });

    const updateAllChecked = this.allChecked;

    updateAllChecked.forEach((item, index) => {
      if (item && item?.idobject === this.file.idArchive) {
        updateAllChecked[index].checked = this.currentChecked;
      }
    });

    this.allChecked = updateAllChecked;
  }

  async setCheckedCurrent() {
    await this.setCheckbox('materiais', false);
  }

  @Watch('topic')
  getFile(): void {
    if (this.topic && this.topic.archives) {
      this.topic.archives.forEach((file: Record<string, any>) => {
        if (String(file.idArchive) === String(this.getParams.fileID)) {
          this.file = file;
          this.pdf = `https://explicae.com.br/app/ver/apostila/${file.slugfix}?token=${this.$store.getters.token}`;
        }
      });
    }
  }

  @Watch('getIdFile')
  async callFunctions() {
    await this.setCheckedCurrent();
    this.getFile();
  }

  @Watch('isMobile', {
    immediate: true
  })
  onMobileChange() {
    this.$store.commit('setSidebar', !this.isMobile);
  }

  @Watch('$route.params')
  @Watch('setSidebarTopic')
  setSidebarMaterials() {
    const topics = this.setSidebarTopic;
    const { context, path } = this.getParams;

    const ComponentClass = Vue.extend(MaterialsSidebar);
    const instance = new ComponentClass({
      propsData: {
        data: topics,
        context,
        path,
        isLoading: this.isLoading
      }
    });

    this.setSidebar(instance);
  }

  isChecked(id: number) {
    const result = this.allChecked.some((checkedItem: Record<string, any>) => {
      if (checkedItem?.idobject === id) {
        return checkedItem.checked;
      }
      return false;
    });
    return result;
  }

  getIds() {
    this.topic.archives.forEach((archive: Record<string, any>) => {
      this.ids.push(archive.idArchive);
    });
  }

  setIsCheckOpen() {
    this.isCheckOpen = !this.isCheckOpen;
  }

  setBreadCrumbs() {
    const { path } = this.getParams;
    if (this.getContext === LIST_NAME_CONTEXT.PLANNER) {
      this.setBreadCrumbsPlanner();
      return;
    }

    if (this.getContext === LIST_NAME_CONTEXT.COURSE) {
      this.setBreadCrumbsCourse();
      return;
    }

    this.$breadcrumb.set([
      { title: 'cadernos', to: '/cadernos' },
      { title: this.currentBook?.title, to: `/caderno/${path}` },
      { title: 'materiais', to: null }
    ]);
  }

  setBreadCrumbsPlanner() {
    this.$breadcrumb.set([
      { title: 'planner', to: '/planner' },
      { title: 'aulas', to: null }
    ]);
  }

  setBreadCrumbsCourse() {
    const { path } = this.getParams;

    const splitPath = path.split('&');
    const slugCourse = splitPath[0];
    const idCourses = splitPath[1];
    const idClass = splitPath[2];

    this.$breadcrumb.set([
      { title: 'cursos', to: `/cursos/${slugCourse}/${idCourses}/${idClass}` },
      { title: 'aulas', to: null }
    ]);
  }
}
