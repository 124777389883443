
























import {
  Component, Vue, Prop, Watch 
} from 'vue-property-decorator';

import Close from '@/assets/icons/Close.vue';
import Arrow from '@/assets/icons/Arrow.vue';

@Component({
  components: {
    Close,
    Arrow
  }
})
export default class MaterialsHeaderAccordion extends Vue {
  @Prop() title!: string;

  private isCheckOpen = true;

  setOpenHeader() {
    this.isCheckOpen = !this.isCheckOpen;
  }

  @Watch('$responsive.contentwidth')
  changeCheckOpenWidth() {
    if (window.innerWidth <= 1200 && this.isCheckOpen !== true) {
      this.isCheckOpen = true;
    }
  }

  @Watch('isCheckOpen')
  emitStatusHeader() {
    this.$emit('status-header', this.isCheckOpen);
  }
}
