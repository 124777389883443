





























import { Component, Vue, Prop } from 'vue-property-decorator';

import ErrorLoading from '@/components/ErrorLoading/ErrorLoading.vue';
import RegroupType from '@/components/RegroupType/index.vue';
import MaterialItem from './MaterialItem/MaterialItem.vue';

import listAccordion from '@/mixins/listAccordion';

@Component({
  mixins: [listAccordion],
  components: {
    RegroupType,
    MaterialItem,
    ErrorLoading
  }
})
export default class MaterialsAccordion extends Vue {
  @Prop() topic!: Record<string, any>;
  @Prop() id!: string;
  @Prop() setCheckbox!: Function;
  @Prop() isChecked!: Function;
  @Prop() currentChecked!: boolean;
  @Prop() errorData!: boolean;
  @Prop() allChecked!: Array<Record<string, any>>;
}
