













































import { Component, Prop, Mixins } from 'vue-property-decorator';

import Checkbox from '@/components/Input/Checkbox/index.vue';
import Tooltip from '@/components/Tooltip/index.vue';

import HistoricService from '@/services/Historic/historicService';

import { hasPermissionMaterials } from '@/share/Util/Permissions/materials';
import ActiveModal from '@/share/Util/ActiveModal';

import { LIST_NAME_CONTEXT } from '@/constant/Context';
import { LIST_PERMISSION } from '@/constant/ListPermission';
import { IDS_FOR_PERMISSION_COURSE } from '@/constant/CoursesId';

import permission from '@/mixins/permission';

const BOOK_REDACTION_NAME_URL = 'redacao';

@Component({
  components: {
    Checkbox,
    Tooltip
  }
})
export default class MaterialItem extends Mixins(permission) {
  @Prop() title!: string;
  @Prop() archive!: Record<string, any>;
  @Prop() setCheckbox!: Function;
  @Prop() checked!: boolean;
  @Prop() allChecked!: Array<Record<string, any>>;
  @Prop() currentChecked!: boolean;

  private setModal = new ActiveModal();
  private historicService = new HistoricService();

  private show = false;

  get getContext() {
    return this.$route.params?.context;
  }

  get idCourseContext() {
    const { path } = this.$route.params;
    const splitPath = path.split('&');

    return splitPath[1];
  }

  get permissionMaterial() {
    if (this.getContext === LIST_NAME_CONTEXT.PLANNER) {
      return this.can(LIST_PERMISSION.PLANNER);
    }

    if (this.getContext === LIST_NAME_CONTEXT.COURSE) {
      return this.can(IDS_FOR_PERMISSION_COURSE[Number(this.idCourseContext)]);
    }

    if (
      this.nameBookURL === BOOK_REDACTION_NAME_URL
      && this.can(LIST_PERMISSION.ALL_MATERIALS_REDACTION)
    ) return true;

    return hasPermissionMaterials(this.archive);
  }

  get nameBookURL() {
    const nameBook = this.$route.params?.path;

    return this.getContext === LIST_NAME_CONTEXT.BOOK ? nameBook : null;
  }

  async handleClick() {
    if (!this.permissionMaterial) {
      this.setModalPermission();
      return;
    }

    this.setPDF();
  }

  setModalPermission() {
    this.setModal.activeModal('modalPlanUpdate');
  }

  async setCheckboxLocal(type: string, checked: boolean) {
    this.show = !checked;

    await this.historicService.saveHistoric({
      idobject: this.archive.idArchive,
      type: 'materiais',
      checked: this.show
    });

    this.allChecked.forEach((item, index) => {
      if (item && item?.idobject === this.archive.idArchive) {
        this.allChecked[index].checked = this.show;
      }
    });
  }

  setPDF(): void {
    const {
      context, path, topicID, fileID 
    } = this.$route.params;

    if (String(this.archive.idArchive) === String(fileID) || !this.permissionMaterial) {
      return;
    }

    this.$router.push({
      name: 'materiais',
      params: {
        context,
        path,
        topicID,
        fileID: String(this.archive.idArchive),
        slugFile: this.archive.slug
      }
    });
  }

  verifyCurrentMaterial(id: string): boolean {
    return id === this.$route.params.fileID;
  }
}
