


















import { Component, Prop, Vue } from 'vue-property-decorator';

import store from '@/store';
import router from '@/router';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

import ChooseIcon from './ChooseIcon.vue';

const NAME_VIDEOS = 'videos';
const NAME_MATERIALS = 'materiais';
const NAME_EXERCICES = 'exercices';
const NAME_ARCHIVES = 'archives';

@Component({
  components: {
    ChooseIcon
  }
})
export default class SubjectOptionsSidebar extends Vue {
  @Prop() data!: Record<string, any>;
  @Prop() context!: string;
  @Prop() page!: string;

  private tabActive = 0;
  private check = true;

  private options = [
    {
      name: 'Aulas',
      value: this.data.videos?.length || 0,
      icon: 'video',
      type: 'videos'
    },
    {
      name: 'Exercícios',
      value: this.data.exercices?.length || 0,
      icon: 'exercise',
      type: 'exercices'
    },
    {
      name: 'Materiais',
      value: this.data.archives?.length || 0,
      icon: 'material',
      type: 'archives'
    }
  ];

  mounted() {
    this.changeIndexActive();
  }

  changeIndexActive() {
    let optionsName = '';
    if (this.page === NAME_VIDEOS) optionsName = 'Aulas';
    if (this.page === NAME_MATERIALS) optionsName = 'Materiais';
    const indexOptions = this.options.findIndex((item) => optionsName === item.name);

    if (indexOptions > -1) {
      this.tabActive = indexOptions;
    }
  }

  changeActive(index: number, name: string, type: string) {
    this.setTrackAmplitude();

    if (type === NAME_VIDEOS && this.page === NAME_MATERIALS) {
      this.setPath();
      return;
    }

    let newType = type;
    if (String(this.data.idTopic) === String(router.currentRoute.params.topicID)) {
      this.check = true;
      this.setFocus(type);
    } else {
      if (newType === NAME_EXERCICES) newType = NAME_VIDEOS;
      this.check = false;
    }

    if (!this.check) this.setPath();

    this.tabActive = index;

    this.saveTabActiveStore(name);
  }

  saveTabActiveStore(name: string) {
    const dataTabActive = {
      component: 'SubjectTools',
      tabName: name
    };

    store.commit('setTabs', dataTabActive);
  }

  setFocus(type: string) {
    if (type === NAME_VIDEOS && router.currentRoute.name === NAME_VIDEOS) {
      const WrapTools = document.getElementById('wrapper-video') as HTMLElement;
      WrapTools.scrollIntoView();
      return;
    }

    if (type === NAME_ARCHIVES && router.currentRoute.name === NAME_MATERIALS) {
      const WrapTools = document.getElementById('wrapper-pdf') as HTMLElement;
      WrapTools.scrollIntoView();
      return;
    }

    const WrapTools = document.getElementById('wrap-tools') as HTMLElement;
    WrapTools.scrollIntoView();
  }

  setPath() {
    const file = this.getFirstFile(this.data, NAME_VIDEOS);
    router.push({
      path: `/videos/${router.currentRoute.params.context}/${router.currentRoute.params.path}/${this.data.idTopic}/${file.id}/${file.slug}`
    });
  }

  getFirstFile(data: Record<string, any>, type: string): Record<string, any> {
    return {
      id: data[type]['0'].idVideo,
      slug: data[type]['0'].slug
    };
  }

  setTrackAmplitude() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'SidebarSubject',
          local: router.currentRoute.name
        }
      }
    });
  }
}
